
import { defineComponent, Ref, ref, watch } from 'vue';
import cCloseButton                         from '@/components/common/close-button/close-button.component.vue';

export default defineComponent({
  name: 'CSidePanel',
  components: {
    cCloseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close-panel'],
  setup(props) {
    const panel: Ref<HTMLElement | null> = ref(null);
    watch(() => props.isVisible, (visible) => {
      setTimeout(() => {
        if (visible && panel.value) {
          panel.value.getElementsByTagName('input')[0].focus()
        }
      }, 300)
    })
    return { panel }
  }
});
