import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center text-20 mx-auto select-none mt-f21"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_with_line = _resolveComponent("icon-arrow-with-line")
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")

  return (_ctx.totalPages > 1)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", {
          class: "c-pagination__icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCurrentPage(1)))
        }, [
          _createVNode(_component_icon_arrow_with_line, { class: "text-white w-6 h-6" })
        ]),
        _createVNode("div", {
          class: "c-pagination__icon",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateCurrentPage(_ctx.pagination.currentPage - 1)))
        }, [
          _createVNode(_component_icon_arrow_left, { class: "text-white w-6 h-6" })
        ]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.totalPages, (number) => {
          return (_openBlock(), _createBlock(_Fragment, { key: number }, [
            (_ctx.checkPaginationElements(number))
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: ["c-pagination__element", 
          number === _ctx.pagination.currentPage
            ? 'c-pagination__element--current'
            : 'c-pagination__element--other'
        ],
                  onClick: ($event: any) => (_ctx.updateCurrentPage(number))
                }, _toDisplayString(number), 11, ["onClick"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (_ctx.pagination.currentPage <= _ctx.totalPages - 5)
          ? (_openBlock(), _createBlock("span", _hoisted_2, "..."))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: ["c-pagination__element", 
        _ctx.totalPages === _ctx.pagination.currentPage
          ? 'c-pagination__element--current'
          : 'c-pagination__element--other'
      ],
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateCurrentPage(_ctx.totalPages)))
        }, _toDisplayString(_ctx.totalPages), 3),
        _createVNode("div", {
          class: "c-pagination__icon",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateCurrentPage(_ctx.pagination.currentPage + 1)))
        }, [
          _createVNode(_component_icon_arrow_left, { class: "text-white w-6 h-6 transform rotate-180" })
        ]),
        _createVNode("div", {
          class: "c-pagination__icon",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateCurrentPage(_ctx.totalPages)))
        }, [
          _createVNode(_component_icon_arrow_with_line, { class: "text-white w-6 h-6 transform rotate-180" })
        ])
      ]))
    : _createCommentVNode("", true)
}