
import { defineComponent } from 'vue';
import IconCheck           from '@/components/icons/icon-check.component.vue';

export default defineComponent({
  name: 'CTableCheckbox',
  components: { IconCheck },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
  },
  emits: ['toggleCheckbox'],
  setup(props, context) {
    const onClick = (): void => {
      context.emit('toggleCheckbox', !props.checked)
    }
    return {
      onClick,
    }
  }

})

