
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconSort',
  props: {
    transformed: {
      default: false,
      type: Boolean,
    },
  },
});
