<template>
  <svg height="36"
       viewBox="0 0 36 36"
       width="36"
       xmlns="http://www.w3.org/2000/svg">

    <g transform="translate(-1670 -223)">
      <rect fill="none"
            height="36"
            opacity="0.03"
            rx="6"
            transform="translate(1670 223)"
            width="36" />

      <g transform="translate(1675.394 209.788)">
        <path d="M46.034,370.826a.371.371,0,0,0,.371-.371V369.27l1.027.593a.371.371,0,0,0,.371-.642l-1.027-.593,1.027-.593a.371.371,0,1,0-.371-.642l-1.027.593V366.8a.371.371,
                0,1,0-.741,0v1.185l-1.026-.593a.371.371,0,0,0-.371.642l1.027.593-1.027.593a.371.371,0,1,0,.371.642l1.026-.593v1.185A.371.371,0,0,0,46.034,370.826Z"
              fill="#fe5b60"
              transform="translate(-41.902 -329.566)" />

        <path d="M273.58,369.727a.371.371,0,0,0,.506.136l1.026-.593v1.185a.371.371,0,0,0,.741,0V369.27l1.027.593a.371.371,0,1,0,.371-.642l-1.026-.593,1.026-.593a.371.371,0,1,
                0-.371-.642l-1.027.593V366.8a.371.371,0,1,0-.741,0v1.185l-1.026-.593a.371.371,0,0,0-.371.642l1.027.593-1.027.593A.371.371,0,0,0,273.58,369.727Z"
              fill="#fe5b60"
              transform="translate(-260.012 -329.566)" />

        <path d="M158.855,369.73a.371.371,0,0,0,.506.136l1.026-.593v1.185a.371.371,0,0,0,.741,0v-1.185l1.027.593a.371.371,0,1,0,.371-.642l-1.027-.593,1.027-.593a.371.371,0,1,
                0-.371-.642l-1.027.593V366.8a.371.371,0,0,0-.741,0v1.185l-1.026-.593a.371.371,0,1,0-.371.642l1.027.593-1.027.593A.371.371,0,0,0,158.855,369.73Z"
              fill="#fe5b60"
              transform="translate(-150.957 -329.569)" />

        <path d="M388.3,369.73a.37.37,0,0,0,.506.136l1.027-.593v1.185a.371.371,0,0,0,.741,0v-1.185l1.026.593a.371.371,0,0,0,.371-.642l-1.027-.593,1.027-.593a.371.371,
                0,0,0-.371-.642l-1.026.593V366.8a.371.371,0,0,0-.741,0v1.185l-1.026-.593a.371.371,0,0,0-.371.642l1.027.593-1.027.593A.37.37,0,0,0,388.3,369.73Z"
              fill="#fe5b60"
              transform="translate(-369.067 -329.569)" />

        <path d="M23.705,34.655H19.281V31.107a.371.371,0,0,0-.741,0v3.547h-4.22l-.2-1.752a1.751,1.751,0,1,0-2.971,0l-.2,1.752H6.733V28.5a.415.415,0,0,1,
                .415-.415H18.125a.415.415,0,0,1,.415.415v.882a.371.371,0,1,0,.741,0V28.5a1.157,1.157,0,0,0-1.156-1.156h-.058V25.16a5.43,5.43,0,1,0-10.86,0V27.34H7.148A1.157,
                1.157,0,0,0,5.992,28.5v6.159H1.567A1.569,1.569,0,0,0,0,36.222v5.263a1.569,1.569,0,0,0,1.567,1.567H4.132a.371.371,0,1,0,0-.741H1.567a.827.827,0,0,1-.826-.826V36.222a.827.827,0,0,1,
                .826-.826H23.705a.827.827,0,0,1,.826.826v5.263a.827.827,0,0,1-.826.826H5.861a.371.371,0,1,0,0,.741H23.705a1.569,1.569,0,0,0,1.567-1.567V36.222a1.569,1.569,0,0,
                0-1.567-1.567ZM14.9,27.34H10.376V25.16a2.26,2.26,0,1,1,4.521,0ZM7.947,25.16a4.689,4.689,0,1,1,9.378,0V27.34H15.638V25.16a3,3,0,0,0-6,0V27.34H7.947Z"
              fill="#fe5b60"
              transform="translate(0 0)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconActionLock',
};
</script>
