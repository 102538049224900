
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { columnHeaderIcon, FilterTypes, sortPayload, tagTypes } from '@/types';
import {
  ActionData,
  columnTypes,
  TableColumn,
  TableRow,
  Tag,
} from '@/components/common/table/table.types';
import {
  useGlobalTranslations,
  useTranslations,
} from '@/hooks/use-translations';
import iconActionLock from '@/components/icons/icon-action-lock.component.vue';
import iconDiscard from '@/components/icons/icon-discard.component.vue';
import iconAccept from '@/components/icons/icon-accept.component.vue';
import cTag from '@/components/common/tag/tag.component.vue';
import { useValidateTable } from '@/components/common/table/use-validate-table';
import CTableCheckbox from '@/components/common/table/table-checkbox.component.vue';
import CDropdown from '@/components/common/dropdown/dropdown.component.vue';
import CDropdownItem from '@/components/common/dropdown-item/dropdown-item.component.vue';
import { ASC, DESC } from '@/helpers/variables';
import IconSort from '@/components/icons/icon-sort.component.vue';
import CTableDropdownContent from '@/components/common/table/table-dropdown-content.component.vue';

export default defineComponent({
  name: 'CTable',
  components: {
    CTableDropdownContent,
    IconSort,
    CDropdownItem,
    CDropdown,
    CTableCheckbox,
    iconActionLock,
    iconDiscard,
    iconAccept,
    cTag,
  },
  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true,
    },
    rows: {
      type: Array as PropType<TableRow[]>,
      required: true,
    },
    selectedItemUuid: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'sort', 'dataError', 'dataEvent', 'filter'],
  setup(props, context) {
    const tableColumns: ComputedRef<TableColumn[]> = computed(
      () => props.columns
    );
    const tableRows: ComputedRef<TableRow[]> = computed(() => props.rows);
    const correctData = ref<boolean>(false);
    const sortedBy = ref<string>(props.columns[0].name);
    const sortedAscending = ref<boolean>(true);

    const sortColumn = (column: TableColumn) => {
      sortedAscending.value =
        sortedBy.value === column.name ? !sortedAscending.value : true;
      let payload: sortPayload = { field: column.name, direction: ASC };

      if (sortedAscending.value) {
        payload.direction = ASC;
      } else {
        payload.direction = DESC;
      }
      context.emit('sort', payload);
      sortedBy.value = column.name;
    };

    const filterColumn = (column: TableColumn, selectedValues: Tag[]): void => {
      column.selectedOptions = selectedValues;
      const payload = { name: column.name, values: selectedValues };
      context.emit('filter', payload);
    };

    const emitRowEvent = (row: TableRow, column: TableColumn): void => {
      if (column.type !== columnTypes.CHECKBOX) {
        context.emit('click', row.uuid);
      }
    };

    const emitDataEvent = (row: TableRow, action: ActionData): void => {
      context.emit('dataEvent', row.uuid, action.name, row);
    };

    const tagClass = (tagType: string): string => {
      if (tagType === tagTypes.ADMIN) {
        return 'admin';
      } else if (tagType === tagTypes.USER) {
        return 'user';
      }
      return '';
    };

    const sortIcon = (columnFilter: FilterTypes): string => {
      switch (columnFilter) {
        case FilterTypes.SORT:
          return columnHeaderIcon.SORT;
        case FilterTypes.MULTI_SELECT:
        case FilterTypes.SINGLE_SELECT:
          return columnHeaderIcon.DROPDOWN;
        default:
          return '';
      }
    };

    watch(
      [tableColumns, tableRows],
      () => {
        const { error, info } = useValidateTable(
          tableRows.value,
          tableColumns.value
        );
        if (error) {
          context.emit('dataError', info);
          correctData.value = false;
        } else {
          correctData.value = true;
        }
      },
      { immediate: true }
    );
    const route = useRoute();

    const path = computed(() => route.path);
    return {
      columnTypes,
      columnHeaderIcon,
      correctData,
      sortedBy,
      sortedAscending,
      sortIcon,
      tagClass,
      emitRowEvent,
      emitDataEvent,
      sortColumn,
      filterColumn,
      useGlobalTranslations,
      useTranslations,
      path,
    };
  },
});
