
import { computed, defineComponent } from 'vue';
import { useGlobals }                from '@/hooks/use-globals';
import iconArrowLeft                 from '@/components/icons/icon-arrow-left.component.vue';
import iconArrowWithLine             from '@/components/icons/icon-arrow-with-line.vue';
import { Pagination }                from '@/store/store.types';

export default defineComponent({
  name: 'CPagination',
  components: { iconArrowWithLine, iconArrowLeft },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['updatePagination'],
  setup(props, context) {
    const { store } = useGlobals();
    const pagination = computed(
      () => store.state.pagination[props.name] as Pagination
    );
    const totalPages = computed(() =>
      Math.ceil(pagination.value.totalElements / pagination.value.pageSize)
    );
    const checkPaginationElements = (number: number) =>
      (number <= pagination.value.currentPage + 3 || number <= 7) &&
      (number >= pagination.value.currentPage - 3 ||
        number >= totalPages.value - 7) &&
      number !== totalPages.value;

    const updateCurrentPage = (value: number) => {
      if (value && value <= totalPages.value) {
        store.commit('updatePaginationCurrentPage', {
          page: value,
          name: props.name,
        });
        context.emit('updatePagination');
      }
    };

    return {
      pagination,
      totalPages,
      updateCurrentPage,
      checkPaginationElements,
    };
  },
});
