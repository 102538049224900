import { ActionData, columnTypes, DataValidation, TableColumn, TableRow, Tag, } from '@/components/common/table/table.types';

export const hexColorRegEX = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const validateTags = (rowIndex: number, data: Tag[]): DataValidation => {
  if (Array.isArray(data)) {
    const isError = data.some((tag: Tag) => {
      if (!Object.values(tag).some((value: string) => value !== null)) {
        return true;
      }
    });

    return isError
      ? {
        error: true,
        info: {
          type: `Content is not a tag. At ${rowIndex} row`,
          occurrence: data,
        },
      }
      : { error: false };
  }

  return {
    error: true,
    info: {
      type: 'Content is not an array',
      occurrence: data,
    },
  };
};

const validateActions = (i: number, data: ActionData[]): DataValidation => {
  const error = {
    error: true,
    info: {
      type: `Content in not an array of actions. At ${+i + 1} row`,
      occurrence: data,
    },
  };

  if (Array.isArray(data)) {
    for (const record of data) {
      if (record === undefined) {
        return error;
      }
    }
  } else {
    return error;
  }

  if (data.length === 0) {
    return error;
  }

  return {
    error: false,
  };
};

export const useValidateTable = (
  tableRows: TableRow[],
  tableColumns: TableColumn[]
): DataValidation => {
  let validationResult = {
    error: false,
  } as DataValidation;
  tableRows.forEach((row: TableRow, rowIndex: number) => {
    tableColumns.forEach((column: TableColumn) => {
      switch (column.type) {
        case columnTypes.TAGS:
          validationResult = validateTags(
            rowIndex,
            row[column.name] as Tag[]
          );
          break;
        case columnTypes.ACTIONS:
          validationResult = validateActions(
            rowIndex,
            row[column.name] as ActionData[]
          );
          break;
        case columnTypes.COLOR:
          if (typeof row[column.name] !== 'string') {
            validationResult = {
              error: true,
              info: {
                type: `Column of type COLOR, has content of type: ${typeof row[
                  column.name
                ]} At ${rowIndex + 1} column`,
                occurrence: row[column.name],
              },
            };
          }

          if (
            typeof row[column.name] === 'string' &&
            !hexColorRegEX.test(row[column.name] as string)
          ) {
            validationResult = {
              error: true,
              info: {
                type: `Column of type COLOR doesn't match hex decimal color pattern. At ${
                  rowIndex + 1
                } column`,
                occurrence: row[column.name],
              },
            };
          }
          break;
        default:
          if (typeof row[column.name] !== 'string') {
            validationResult = {
              error: true,
              info: {
                type: `Column of type TEXT, has content of type: ${typeof row[
                  column.name
                ]}. At ${rowIndex + 1} row`,
                occurrence: row[column.name],
              },
            };
          }
          break;
      }

      if (validationResult.error) return validationResult;
    });
  });
  return validationResult;
};