<template>
  <svg class="fill-current"
       viewBox="0 0 18 18">
    <path d="M75.5,0.3l-1.2-1.1c-1.2-1.1-3.1-1.1-4.2,0.1l-11.3,11l0,0c-0.1,0.1-0.3,0.1-0.4,0l-2.2-2.7
	c-1.1-1.3-3-1.4-4.2-0.4l-1.2,1c-1.3,1.1-1.5,3-0.4,4.2l4.4,5.3c0.9,1.1,2.2,1.7,3.6,1.7h0.2c0,0,0,0,0,0c1.3,0,2.6-0.5,3.5-1.5
	l5-4.9c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.5-1.2-0.5-1.6,0l-5,4.9c-0.1,0.1-0.1,0.1-0.2,0.2c-1.1,0.9-2.7,0.8-3.6-0.3L52,11.1
	c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.3-0.5l1.2-1c0.3-0.3,0.8-0.2,1,0.1l2.2,2.7c0.1,0.1,0.1,0.1,0.2,0.2
	c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.3-0.3,1.8-0.8L71.7,0.9c0.3-0.3,0.8-0.3,1,0L73.9,2c0.3,0.3,0.3,0.8,0,1l-6,5.9
	c-0.4,0.4-0.4,1.1,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l6-6C76.7,3.4,76.7,1.5,75.5,0.3z" />
    <path d="M11.8,9l5.6-5.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L9,6.2L3.4,0.6c-0.8-0.8-2-0.8-2.8,0
	c-0.8,0.8-0.8,2,0,2.8L6.2,9l-5.6,5.6c-0.8,0.8-0.8,2,0,2.8C1,17.8,1.5,18,2,18s1-0.2,1.4-0.6L9,11.8l5.6,5.6C15,17.8,15.5,18,16,18
	s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L11.8,9z" />
  </svg>
</template>

<script>
export default {
  name: 'IconDiscard'
}
</script>

<style scoped>

</style>