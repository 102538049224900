
import { defineComponent } from 'vue';
import iconClose           from '@/components/icons/icon-close.component.vue';

export default defineComponent({
  name: 'CCloseButton',
  components: {
    iconClose,
  },
  emits: ['click'],
  setup(props, context) {
    const handleClick = (event: MouseEvent): void => {
      context.emit('click', event);
    };
    return {
      handleClick,
    };
  },
});
