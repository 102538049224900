
import { defineComponent, PropType, ref, Ref } from 'vue';
import { FilterTypes }                         from '@/types';
import { Tag }                                 from '@/components/common/table/table.types';

export default defineComponent({
  name: 'CTableDropdownContent',
  props: {
    group: {
      type: Object as PropType<Tag[]>,
      required: true,
    },
    type: {
      type: String as PropType<FilterTypes>,
      required: true,
    },
    selectedValues: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
  },
  emits: [ 'updateSelected' ],

  setup(props, context) {
    const checkedTags: Ref<Tag[]> = ref(props.selectedValues);
    const updateCheckedTags = (tag: any): void => {
      if (props.type === FilterTypes.SINGLE_SELECT) {
        checkedTags.value = props.selectedValues[0].name === tag.name ? [] : [ tag ];
      }
      context.emit('updateSelected', checkedTags.value);
    };
    return {
      checkedTags,
      FilterTypes,
      updateCheckedTags,
    };
  },
});
