
import { defineComponent, ref }  from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import iconMagnifyingGlass       from '@/components/icons/icon-magnifying-glass.component.vue';

export default defineComponent({
  name: 'CSearchbar',
  components: {
    iconMagnifyingGlass,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: [ 'enter', 'update:search' ],
  setup() {
    const placeholder = `${useGlobalTranslations('search')}...`;
    const input = ref<HTMLInputElement>();
    const isFocused = ref<boolean>(false);

    const setFocus = (): void => {
      if (input.value !== undefined) {
        input.value?.focus();
      }
    };

    return {
      isFocused,
      placeholder,
      input,
      setFocus,
    };
  },
});
