import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vModelDynamic as _vModelDynamic, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex p-f13 flex-wrap z-9999" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.group, (tag, index) => {
      return (_openBlock(), _createBlock("label", {
        key: index,
        class: "tag py-f5 px-f8 m-f3 text-11 rounded-full select-none text-white cursor-pointer transition",
        style: {backgroundColor: _ctx.checkedTags.includes(tag) ? tag.color || 'var(--primary-500)' : 'var(--gray-500)'}
      }, [
        _withDirectives(_createVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkedTags = $event)),
          class: "hidden",
          type: 'checkbox',
          value: tag,
          onChange: ($event: any) => (_ctx.updateCheckedTags(tag))
        }, null, 40, ["value", "onChange"]), [
          [_vModelDynamic, _ctx.checkedTags]
        ]),
        _createTextVNode(" " + _toDisplayString(tag.name), 1)
      ], 4))
    }), 128))
  ]))
}