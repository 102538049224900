<template>
  <svg viewBox="0 0 41.2 32.4"
       xml:space="preserve"
       xmlns="http://www.w3.org/2000/svg">
    <path class="opacity-0"
          d="M5.7,1.4l32.3-0.9l-4.6,28.6L5.7,25.4V1.4z" />
    <path class="fill-current"
          d="M35.1,6.6 M32.8,2.2L15.2,19.8c-0.1,0.1-0.1,0.1-0.2,0l-6.5-6.5c-1.7-1.7-4.3-1.7-6,0l0,0c-1.7,1.7-1.7,4.3,0,6l11.8,11.8
	c0.4,0.4,1.2,0.4,1.6,0L38.8,8.2c1.7-1.7,1.7-4.3,0-6l0,0C37.1,0.5,34.4,0.5,32.8,2.2z" />
  </svg>
</template>

<script>
export default {
  name: 'IconAccept'
}
</script>

<style scoped>

</style>

