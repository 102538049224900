import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")

  return (_openBlock(), _createBlock("div", {
    class: "group cursor-pointer rounded-md border-primary-400 border-solid border-3 w-8 h-8 flex items-center justify-center hover:border-3 hover:border-primary-600 transition ease in",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createVNode(_component_icon_close, { class: "text-primary-400 group-hover:text-primary-600 group-hover:scale-90 transform transition ease-in w-4 h-4" })
  ]))
}